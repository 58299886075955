
























import {Component, Vue} from 'vue-property-decorator';
import googleDriveService from "@/service/google-drive.service";
import SongsListItem from "@/components/songs/SongsListItem.vue";
import {SongBooking} from "@/model/song-booking";


@Component({
  components: {SongsListItem}
})
export default class SongsList extends Vue {

  bookedSongs: Array<SongBooking> = [];
  tempSongs: Array<SongBooking> = [];
  loading = true;

  mounted() {
    this.loading = true;

    this.bookedSongs = this.fetch();


    setInterval(() => {
      this.tempSongs = this.fetch();
    }, 3000);
     setInterval(() => {
       if (this.tempSongs.length){
         this.bookedSongs = this.tempSongs;
       }
     }, 4000)
  }

  fetch(): SongBooking[] {
    this.loading = true;
    const items: Array<SongBooking> = [];
    googleDriveService.readData().then(response => {
      response.loadInfo().then(() => {
        this.loading = false;
        const sheet = response.sheetsByIndex[0]
        sheet.getRows()
            .then((r: SongBooking[]) => {
              r.sort((a, b) => (a?.id! > b?.id! ? 1 : -1)).forEach((item: SongBooking) => {
                items.push(
                    item
                )
              });
            })
      })
    })
    return items;
  }

  getTitle(item: SongBooking): string {
    return item.artist + ' - ' + item.song;
  }
}
