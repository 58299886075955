









import {Component, Vue} from 'vue-property-decorator'
import WorkoutPlanList from "@/components/songs/SongsList.vue";

@Component({
  components: {WorkoutPlanList}
})
export default class Songs extends Vue {
}
