













import {Component, Prop, Vue} from 'vue-property-decorator';
import {SongBooking} from "@/model/song-booking";

@Component({})
export default class SongsListItem extends Vue {

  @Prop()
  item!:SongBooking;

  clean(text: string): string {
    return text.replace('*', '')
  }


}
