import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

import Songs from "@/views/Songs.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Songs',
    component: Songs
  },
  {
    path: "/http*",
    beforeEnter: to => {
      window.open(to.fullPath.substring(1), '_blank');
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();

});

export default router
