import {SONGS_LIST_SPREADSHEET_ID} from "@/constants/google-spreadsheet-constant";
import {GoogleSpreadsheet} from 'google-spreadsheet';
const secretKey = require('./secrect/streamer-libs-74c90432f68b.json');

class GoogleDriveService {
    public async readData(): Promise<GoogleSpreadsheet> {
        return new Promise((resolve) => {
            const doc = new GoogleSpreadsheet(SONGS_LIST_SPREADSHEET_ID);
            doc.useServiceAccountAuth(secretKey);
            resolve(doc)
        })

    }
}


export default new GoogleDriveService();
